import { useQuery } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { SET_CATEGORIES_STATE } from 'constants/action_types/categories'
import { getFormattedCountryCategories } from 'helpers/categories'

const EXPECTED_MARKETPLACES = [
  'us',
  'ca',
  'mx',
  'uk',
  'de',
  'fr',
  'it',
  'es',
  'in',
  'jp'
]

const isValidResponse = data => {
  const hasExpectedMarketplaces =
    data && Object.keys(data)?.length >= EXPECTED_MARKETPLACES.length
  const hasUSCategories = data?.us?.length > 0

  return hasExpectedMarketplaces && hasUSCategories
}

export const useCategories = () => {
  const dispatch = useDispatch()
  const { categories, loaded } = useSelector(state => state.categories)

  useQuery('categories', {
    queryFn: () =>
      fetch(`${process.env.REACT_APP_STATIC_API_URL}/categories.json`).then(
        response => response.json()
      ),
    enabled: !loaded,
    select: r => r,
    onSuccess: data => {
      if (isValidResponse(data)) {
        dispatch({
          type: SET_CATEGORIES_STATE,
          payload: {
            categories: data,
            loaded: true
          }
        })
      }
    },
    retry: false,
    refetchOnWindowFocus: false
  })

  // Filter by country, map the label and value, and sort alphabetically
  const getCountryCategories = (country, filterSupported) =>
    getFormattedCountryCategories(categories, country, filterSupported)

  return {
    categories,
    getCountryCategories
  }
}
